@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply leading-[normal] m-0;
    background-color: #faf9ff !important;
  }
  *,
  ::before,
  ::after {
    border-width: 0;
  }